import React, { useState, useEffect, useMemo } from 'react';
import { buzzwordsArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import Rollup from '../../components/Rollup';
import ChartArea from '../../components/ChartArea';
import ChartDetailedInStacked from '../../components/ChartDetailedInStacked';
import { ListProps } from '../../types/interfaces';

function Buzzwords({ premium = false }: { premium?: boolean }) {
  const [selectedBuzzword, setSelectedBuzzword] = useState<ListProps | null>(null);
  const listLimit = 5;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getTimestamps = () => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

    return {
      currentTimestamp,
      oneMonthAgoTimestamp,
    };
  };

  useEffect(() => {
    const fetchBuzzwords = async () => {
      setLoading(true);
      setError(null);

      const { currentTimestamp, oneMonthAgoTimestamp } = getTimestamps();

      try {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-keywords?interval=daily&startDate=${oneMonthAgoTimestamp}&endDate=${currentTimestamp}`
            : `${process.env.REACT_APP_AWS_API}/api/top-keywords?interval=daily&startDate=${oneMonthAgoTimestamp}&endDate=${currentTimestamp}`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // setTopTokens(data);
        if (data.length > 0) {
          // setSelectedCoin(createListItem(data[0]));
        }
      } catch (error) {
        console.error('Error fetching top tokens:', error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(String(error));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBuzzwords();
  }, []);

  const createListItem = (category: ListProps, onClick?: () => void): ListProps => ({
    ...category,
    onClick: onClick || (() => {}),
  });
  const listItems: ListProps[] = useMemo(
    () =>
      buzzwordsArray.slice(0, listLimit).map((category) => {
        const updatedBuzzword = {
          ...category,
          tooltipValue: category.title,
          title: '',
          secondTitle: '',
          thirdTitle: category.title.replace(/decentralized/gi, 'D.'),
        };

        return createListItem(updatedBuzzword, () => {
          setSelectedBuzzword(updatedBuzzword);
        });
      }),
    [],
  );

  useEffect(() => {
    if (listItems.length > 0) {
      setSelectedBuzzword(listItems[0]);
    }
  }, [listItems]);

  const renderListItem = (item: ListProps, index: number) => (
    <List
      key={index}
      {...item}
      title={item.thirdTitle ? item.thirdTitle : ''}
      thirdTitle={item.title}
      stats={item.stats.slice(0, 2)}
      spark={item.spark ? item.spark : []}
    />
  );

  return (
    <FeatureWithInteractiveList
      premium={premium}
      title="Buzzwords"
      listItems={listItems}
      selectedItem={selectedBuzzword}
      setSelectedItem={setSelectedBuzzword}
      FeaturedComponent={ChartDetailedInStacked}
      featuredStatName="Average Views"
      featureBackground={false}
      renderListItem={renderListItem}
      renderLeftDrawerContent={() => (
        <>
          <ChartArea
            y1Measuring={'views'}
            height={200}
            data={buzzwordsArray[0].coords}
            y1Colour={'#50B8E7'}
            marginTop={20}
            headerContent={''}
            isMicroTooltip={true}
          />

          <div>
            <Rollup label="Mentions" value={99} />
            <Rollup label="Views" value={3000000} />
            <Rollup label="Channels" value={3} />
          </div>
        </>
      )}
      renderRightDrawerContent={() => (
        <div className="list">{buzzwordsArray.map((category, index) => renderListItem(createListItem(category), index))}</div>
      )}
    />
  );
}

export default Buzzwords;
