import { useState, useEffect } from 'react';

export interface ChatterProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

export interface PendingProps {
  videoId: string;
  channelName: string;
  videoLink: string;
  title: string;
  views: number;
  cryptoProjects: string[];
}

function SponsorAd({ title = 'Sponsor' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPending, setLoadingPending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false)
  const [sentimentData, setSentimentData] = useState<ChatterProps[] | null>(null);
  const [topViews, setTopViews] = useState<PendingProps[] | null>(null);
  const [count, setCount] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in-slow');

  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  };

  useEffect(() => {

    const fetchTopTokens = async () => {
      setLoading(true);
      setError(false);

      const { currentDate, oneMonthAgoDate } = getTimestamps();

      try {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`
            : `${process.env.REACT_APP_AWS_API}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: ChatterProps[] = await response.json();

        setSentimentData(data);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
        // setError(error instanceof Error ? error.message : String(error));
        setError(true);
      } finally {
        setLoading(false);
        setError(false);
      }

      try {
        const responsePending = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/pending-videos-crypto-projects`
            : `${process.env.REACT_APP_AWS_API}/api/pending-videos-crypto-projects`,
        );

        if (!responsePending.ok) {
          throw new Error(`HTTP error! status: ${responsePending.status}`);
        }
        const dataPending: PendingProps[] = await responsePending.json();
        setTopViews(dataPending);

        // Sort the array by views in descending order and take top 10
        const top10MostViewed = dataPending
          .sort((a, b) => (b.views || 0) - (a.views || 0))
          .slice(0, 10);

      } catch (error) {
        console.error('Error fetching top tokens:', error);

      } finally {
        setLoadingPending(false);
      }
    };

    fetchTopTokens();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (sentimentData && prevCount < sentimentData.length - 1) {
          return prevCount + 1;
        } else {
          return 0; // Reset to the first item
        }
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [sentimentData]);

  useEffect(() => {
    // Reset the class to trigger re-animation
    setFadeClass('');
    const timeout = setTimeout(() => {
      setFadeClass('fade-in-slow');
    }, 50); // Small delay to allow the class to be removed and re-added

    return () => clearTimeout(timeout);
  }, [count]);


  return (
    <>
      <div className="sponsor-container">

        <div className="sponsor-data">
          {topViews && topViews.length > 0 && (
            <>
              <div className={`sponsor-data ${fadeClass}`}>
                <img src={`https://img.youtube.com/vi/${topViews[count].videoId}/mqdefault.jpg`} alt="Logo" />
              </div>
            </>
          )}

        </div>
        <div className="sponsor-data">
          {topViews && topViews.length > 0 &&
            <>
              <a href={topViews[count].videoLink} target="_blank">{topViews[count].channelName} <i className="fa-solid fa-link"></i></a>
              <b><small>VIEWS</small>{topViews[count].views.toLocaleString()}</b>
            </>
          }
        </div>




      </div>
    </>
  );
}

export default SponsorAd;