import React, { useState, useEffect, useMemo } from 'react';
import { coinsArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import ChartArea from '../../components/ChartArea';
import ChartDetailedInFull from '../../components/ChartDetailedInFull';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

function CoinsTopDefault({ premium = true }: { premium?: boolean }) {
    const [selectedCoin, setSelectedCoin] = useState<ListProps | null>(null);
    const [topTokens, setTopTokens] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isTopTokens, setIsTopTokens] = useState<boolean>(false);

    const convertToCoordinates = (
        data: Array<{ interval_start: string; weighted_view_score: number }>,
    ): Array<{ x: number; y: number }> => {
        if (!data || data.length === 0) return [];

        const sortedData = data.sort((a, b) => new Date(a.interval_start).getTime() - new Date(b.interval_start).getTime());
        const startDate = new Date(sortedData[0].interval_start);
        const endDate = new Date(sortedData[sortedData.length - 1].interval_start);

        const result: Array<{ x: number; y: number }> = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const timestamp = Math.floor(currentDate.getTime() / 1000);
            const dataPoint = sortedData.find((item) => new Date(item.interval_start).toDateString() === currentDate.toDateString());

            result.push({
                x: timestamp,
                y: dataPoint ? dataPoint.weighted_view_score : 0,
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return result;
    };

    const createListItem = (coin: any, onClick?: () => void): ListProps => {
        const processedLogo = coin.logo ? coin.logo.replace('/thumb/', '/large/') : coin.logo;

        return {
            label: 'Coin',
            title: coin.projectName,
            secondTitle: coin.tokenSymbol,
            thirdTitle: coin.chainName || `${coin.projectName}`,
            categories: coin.Category,
            price: coin.currentPrice,
            percentChange: coin.percentChange,
            logo: processedLogo,
            stats: [
                { label: 'views', value: coin.total_weighted_view_score },
                { label: 'change', value: coin.percentChange, isPercentage: true },
                { label: 'mentions', value: coin.total_mentions },
                { label: 'channels', value: 0 },
            ],
            spark: [6, 12, 73, 8, 12, 33],
            coords: coin.timeSeriesData ? convertToCoordinates(coin.timeSeriesData) : [],
            onClick: onClick || (() => { }),
        };
    };

    const listItems: ListProps[] = useMemo(
        () =>
            topTokens?.slice(0, 8).map((coin: any) => {
                const listItem = createListItem(coin);
                return {
                    ...listItem,
                    onClick: () => {
                        setSelectedCoin(listItem);
                    },
                };
            }) || [],
        [topTokens],
    );

    const getTimestamps = () => {
        const formatDate = (date: any) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const currentTimestamp = Math.floor(Date.now() / 1000);
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

        return {
            currentTimestamp,
            oneMonthAgoTimestamp,
            currentDate: formatDate(new Date()),
            oneMonthAgoDate: formatDate(oneMonthAgo),
        };
    };

    useEffect(() => {
        if (listItems.length > 0) {
            setSelectedCoin(listItems[0]);
        }

        const fetchTopTokens = async () => {
            setLoading(true);
            setError(null);

            const { currentDate, oneMonthAgoDate } = getTimestamps();

            try {
                const endpoint = isTopTokens ? 'top-tokens' : 'top-new-tokens';
                const response = await fetch(
                    process.env.REACT_APP_USE_REMOTE_API === 'true'
                        ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/${endpoint}?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}`
                        : `${process.env.REACT_APP_AWS_API}/api/${endpoint}?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}`,
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTopTokens(data);
                if (data.length > 0) {
                    setSelectedCoin(createListItem(data[0]));
                }
            } catch (error) {
                console.error('Error fetching top tokens:', error);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError(String(error));
                }
            } finally {
                setLoading(false);
            }
        };

        fetchTopTokens();
    }, [isTopTokens]);

    const renderListItem = (item: ListProps, index: number) => (
        <List
            key={index}
            {...item}
            logo={item.logo ? item.logo : noLogoPath}
            secondTitle={item.secondTitle?.toUpperCase()}
            stats={item.stats.slice(0, 2)}
            spark={item.coords ? item.coords.map((coord) => coord.y) : []}
            selected={!!(selectedCoin && item.title === selectedCoin.title)}
        />
    );

    // Create the toggle button
    const toggleButton = (
        <button className="toggle" onClick={() => setIsTopTokens(!isTopTokens)}>{isTopTokens ? "VIEW RECENTLY DISCUSSED COINS" : "VIEW TOP COINS"}</button>
    );

    return (
        <>
            <FeatureWithInteractiveList
                premium={premium}
                title={isTopTokens ? "TOP COIN VIEWS" : "RECENTLY DISCUSSED COINS"}
                listItems={listItems}
                selectedItem={selectedCoin}
                setSelectedItem={setSelectedCoin}
                FeaturedComponent={ChartDetailedInFull}
                featuredStatName="Views Score"
                featureBackground={isTopTokens}
                renderListItem={renderListItem}
                renderLeftDrawerContent={() => (
                    <>
                        <ChartArea
                            y1Measuring={'mentions'}
                            height={200}
                            data={coinsArray[0].coords}
                            y1Colour={'#50B8E7'}
                            marginTop={20}
                            headerContent={''}
                            isMicroTooltip={true}
                        />
                    </>
                )}
                renderRightDrawerContent={() => (
                    <div className="list">{coinsArray.map((coin, index) => renderListItem(createListItem(coin), index))}</div>
                )}
                toggleButton={toggleButton}
            />
        </>
    );
}

export default React.memo(CoinsTopDefault);